import DOMPurify from 'isomorphic-dompurify';

type RenderSimpleHtmlProps = {
    html: string;
    className?: string;
};

const RenderSimpleHtml = (props: RenderSimpleHtmlProps) => {
    const { html, className } = props;

    if (!html) return null;

    const sanitizedData = (htmlToSanitize: string = '') => ({
        __html: DOMPurify.sanitize(
            htmlToSanitize?.replace?.(/“|”/g, '"').replace(/cms\.blockworks\.co/g, 'blockworks.co'),
        ),
    });

    return <div className={className} dangerouslySetInnerHTML={sanitizedData(html)} />;
};

export { RenderSimpleHtml };
